import { ComboPromotionModel } from '@configs/models/promotion.model';
import PromotionListComboListItem from '@modules/products/PromotionListComboListItem';
import { Typography } from 'antd';
import React from 'react';

function ProductComboList({
  comboPromotions,
  productName,
}: {
  comboPromotions: ComboPromotionModel[];
  productName?: string;
}) {
  if (!comboPromotions?.length) return null;

  return (
    <div className="flex flex-col gap-2">
      <Typography.Title level={5} className="m-0 text-sm text-primary">
        Ưu đãi khi mua combo với {productName ? `${productName}` : ''}
      </Typography.Title>
      {comboPromotions.map((promotion) => (
        <PromotionListComboListItem
          comboPromotion={promotion}
          key={promotion.key}
        />
      ))}
    </div>
  );
}

export default ProductComboList;
