import { FireOutlined } from '@ant-design/icons';
import { PromoPercentProd } from '@configs/models/promotion.model';
// import CurrencyUtils from '@libs/utils/currency.utils';
import TimeUtils from '@libs/utils/time.utils';
import { Typography } from 'antd';
import React from 'react';

function PromotionListPercentListItem({
  promotion,
  retailPrice,
}: {
  promotion: PromoPercentProd;
  retailPrice?: number;
}) {
  return (
    <div
      key={promotion.promoPercentKey}
      className="my-2 flex items-center gap-2 px-4"
    >
      <div className="flex h-[32px] w-[32px] border-collapse items-center justify-center rounded-lg bg-primary-background">
        <FireOutlined size={20} className="text-primary" />
      </div>
      <div>
        <Typography.Paragraph className="m-0">
          Ưu đãi bất ngờ khi mua ít nhất{' '}
          {promotion.promoPercentProductQuantityMinCondition} sản phẩm
          {/* {(promotion?.promoPercentVal * 100).toFixed(2)}%
          {retailPrice ? (
            <>
              , chỉ còn{' '}
              <b className="text-primary">
                {CurrencyUtils.format(
                  retailPrice * (1 - promotion.promoPercentVal)
                )}{' '}
              </b>
            </>
          ) : (
            ''
          )} */}
        </Typography.Paragraph>
        <Typography.Paragraph className="m-0 text-xs text-gray-500">
          Ưu đãi áp dụng đến{' '}
          {TimeUtils.formatDate(promotion.promoEndDate, {
            noTime: true,
          })}
        </Typography.Paragraph>
      </div>
    </div>
  );
}

export default PromotionListPercentListItem;
