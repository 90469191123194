import CurrencyUtils from '@libs/utils/currency.utils';
import useProductAutoLoadByIds from '@libs/utils/hooks/useProductAutoLoadByIds';
import { Typography } from 'antd';
import Link from 'next/link';
import React from 'react';
import AddToCartButton from './AddToCartButton';
import { getProductName } from '@libs/helpers';
import { ComboPromotionModel } from '@configs/models/promotion.model';
import ImageWithFallback from '@components/templates/ImageWithFallback';
import { Plus } from 'react-feather';

function PromotionListComboListItem({
  comboPromotion,
}: {
  comboPromotion: ComboPromotionModel;
}) {
  const productIds = [...(comboPromotion.policies?.map((p) => p.prodId) || [])];
  const { products } = useProductAutoLoadByIds(productIds);

  return (
    <div
      key={comboPromotion.key}
      className="border-1 flex flex-col items-start gap-2 rounded-md border-solid border-primary-light bg-primary-background lg:flex-row lg:items-center"
    >
      <div className="flex items-center">
        <div className="flex flex-1 flex-col gap-2 ">
          <Typography.Paragraph className="text-md m-0 bg-primary-light p-2 font-bold text-white">
            {comboPromotion.name}
          </Typography.Paragraph>
          <div className="flex w-full flex-col items-stretch gap-2 px-4 pb-4">
            <ul className="m-0 block rounded-lg border border-solid border-blue-200 bg-blue-50 py-2 px-3">
              {comboPromotion.policies?.map((policy) => {
                const product = products.find((p) => p.key === policy.prodId);
                return (
                  <li
                    key={policy.prodId}
                    className="my-1 flex items-center gap-2 px-0 text-xs text-black"
                  >
                    <span>•</span>
                    <ImageWithFallback
                      src={product?.detail?.image || ''}
                      width={24}
                      height={24}
                      className=" inline-block align-middle"
                    />
                    <Link
                      href={`/${product?.productType?.seoUrl}/${product?.detail?.seoUrl}`}
                      key={policy.prodId}
                      passHref
                    >
                      <a className="flex-1 hover:text-primary">
                        {policy.requiredProdQty} x{' '}
                        <b
                          className="font-medium"
                          style={{ cursor: 'pointer' }}
                        >
                          {getProductName(product)}
                        </b>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
            {/* <div className="flex items-center ">
              {comboPromotion.totalAmount != comboPromotion.totalCost && (
                <Typography.Paragraph className="text m-0 line-through">
                  {CurrencyUtils.format(comboPromotion.totalAmount)}
                </Typography.Paragraph>
              )}
              &nbsp;
              <Typography.Paragraph className="text text-bold m-0 text-primary">
                <span className="text-xs text-gray-900">Chỉ còn </span>
                <b>{CurrencyUtils.format(comboPromotion.totalCost)}</b>
              </Typography.Paragraph>
            </div> */}

            <div className="ml-0 w-[fit-content] max-w-[124px] lg:ml-0">
              <AddToCartButton
                comboPromotion={comboPromotion}
                className="h-[32px] w-[128px] rounded-lg text-left"
                label={
                  <div className="flex items-center">
                    <Plus size={16} className="mr-1" />
                    <p className="m-0 text-xs font-semibold">Thêm vào giỏ</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionListComboListItem;
